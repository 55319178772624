@import "core";
.section.header {
	.header-account {
		margin-left: 4rem;
		width: 10.6rem;
		position: relative;

		.account-userbtn {
			padding: 0 1.5rem;

			.btn-icon {
				font-size: .8rem;
				margin-left: .8rem;
			}

			.btn-content {
				flex-wrap: nowrap;
				justify-content: space-between;
				width: 100%;
			}

			.btn-text {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.account-usermenu {
			color: $color-text;
			width: 30rem;
			position: absolute;
			right: 0;
			top: calc(100% + 1.4rem);
			background-color: $color-white;
			border-radius: $radius-small;
			padding: 2rem 2rem .8rem;
		}

			.usermenu-greeting {
				@include font-bold;
				display: block;
				font-size: 1.6rem;
			}

			.usermenu-nav {
				margin-top: .8rem;
			}

				.nav-link {
					@include font-medium;
					display: block;
					line-height: 4.7rem;
					width: calc(100% + 4rem);
					margin: 0 -2rem;
					padding: 0 2rem;
					transition: background-color $transition-hover ease;
					position: relative;

					&:hover {
						background-color: $color-bg;
					}

					+ .nav-link {
						&:before {
							@include pseudo;
							top: 0;
							left: 2rem;
							width: calc(100% - 4rem);
							height: .1rem;
							background-color: $color-border;
						}
					}
				}

					.link-icon {
						margin-right: 1.5rem;
						width: 1.4rem;
						font-size: 1.4rem;
					}
	}
}